import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import TextFieldControl from 'components/inputs/TextFieldControl';
import CheckboxControl from 'components/inputs/CheckboxControl';
import ActionButton, { ActionButtonEnum } from 'components/buttons/ActionButton';
import InsertButton from 'components/buttons/InsertButton';

type FewAnswerProps = {
  form: UseFormReturn;
  elementIndex: number;
};

const FewAnswer: FC<FewAnswerProps> = ({ form, elementIndex }) => {
  const { control } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    keyName: 'formId',
    name: `elements.[${elementIndex}].elementParts`
  });

  const appendCheckbox = () => {
    append({ name: '', correctAnswerBool: false });
  };

  const removeCheckbox = (index: number) => {
    remove(index);
  };

  return (
    <Stack sx={{ mt: 3 }}>
      <Stack spacing={2}>
        {fields.map((element: any, index) => (
          <Stack direction='row' spacing={2} alignItems='center' key={`multiple-answer-${element.formId}`}>
            <Typography>{`${index + 1})`}</Typography>
            <CheckboxControl label='' name={`elements.[${elementIndex}].elementParts.[${index}].isCorrect`} />
            <InsertButton form={form} inputName={`elements.[${elementIndex}].elementParts.[${index}].content`}>
              <TextFieldControl
                placeholder='Ответ'
                name={`elements.[${elementIndex}].elementParts.[${index}].content`}
                control={control}
                rules={{ required: true }}
                multiline
              />
            </InsertButton>

            <ActionButton
              sx={{ ml: '24px !important' }}
              handleClick={() => removeCheckbox(index)}
              type={ActionButtonEnum.DELETE}
            />
          </Stack>
        ))}
      </Stack>

      <Button sx={{ mt: 3, width: 'fit-content' }} startIcon={<AddIcon />} onClick={appendCheckbox}>
        Добавить вариант ответа
      </Button>
    </Stack>
  );
};

export default FewAnswer;
