import React, { FC } from 'react';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { ElementFileType } from '@generated/graphql';
import { useFieldArray, UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import SelectFiles from './SelectFiles';
import { ExerciseForm, FilesFieldName } from '../../types';

type FilesProps = {
  form: UseFormReturn<ExerciseForm>;
  elementIndex: number;
  disabled?: boolean;
};

const Files: FC<FilesProps> = ({ form, elementIndex, disabled }) => {
  const { control } = form;
  const filesFieldName: FilesFieldName = `elements.${elementIndex}.files`;
  const fieldArray = useFieldArray({
    control,
    keyName: 'formId',
    name: filesFieldName,
    rules: {
      minLength: 2,
      required: true,
      validate: (value) => value.every((f: any) => !!f.type && ((!f.id && f.file) || f.id))
    }
  }) as unknown as UseFieldArrayReturn;
  const { fields, append } = fieldArray;

  const appendFile = () => {
    append([{ type: ElementFileType.Cover }, { type: ElementFileType.Download }]);
  };

  return (
    <Stack sx={{ mt: 3 }}>
      <Grid container spacing={1.5}>
        <Grid item xs={6}>
          <Typography>Обложка</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>Файл</Typography>
        </Grid>
        {fields?.map((field: any, index: number) => (
          <Grid item xs={6} key={`files-${field.formId}`}>
            <SelectFiles {...{ field, form, fieldArray, index, elementIndex, disabled }} />
          </Grid>
        ))}

        <Grid item xs={12}>
          <Button startIcon={<AddIcon />} onClick={appendFile}>
            файл
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Files;

Files.defaultProps = {
  disabled: false
};
