import { Avatar, Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { ArrayElement } from 'types/common';
import { CourseCuratorsQuery } from '@generated/graphql';
import { FC, useContext } from 'react';
import createUserName from 'helpers/common';
import { ROLE_NAME } from 'constants/global';
import { Link } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { Close } from '@mui/icons-material';
import { ModalContext } from 'context/modalContext';

type Curator = ArrayElement<CourseCuratorsQuery['courseCurators']>;
const CuratorCard: FC<{ curator: Curator; onDelete: () => any }> = ({ curator, onDelete }) => {
  const { handleOpen } = useContext(ModalContext);

  const { user } = curator;
  const handleDelete = () =>
    handleOpen({
      title: 'Удалить куратора',
      content: `Вы уверены, что хотите удалить куратора ${createUserName(
        user
      )} из курса? Отменить это действие будет невозможно!`,
      buttonText: 'Удалить',
      handleClick: async () => onDelete()
    });

  return (
    <Box sx={{ cursor: 'pointer', position: 'relative', display: 'block' }}>
      <Stack alignItems='center' spacing={1} component={Link} to={`/${ROUTES.USERS}/${user.id}`}>
        <Typography>{`ID: ${user.numericId}`}</Typography>
        <Avatar src={user.avatarFile?.url} />
        <Typography textAlign='center'>{`ФИО: ${createUserName(user)}`}</Typography>
        <Typography textAlign='center'>{`Роль: ${user.role ? ROLE_NAME[user.role] : '-'}`}</Typography>
      </Stack>
      <Tooltip title='Удалить куратора'>
        <IconButton
          sx={{ position: 'absolute', top: -8, right: 0 }}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
        >
          <Close />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default CuratorCard;
