import React, { FC } from 'react';
import { Stack } from '@mui/material';
import TextFieldControl from 'components/inputs/TextFieldControl';

type TextAnswerProp = {
  elementIndex: number;
  disabled?: boolean;
};
const TextAnswer: FC<TextAnswerProp> = ({ elementIndex, disabled }) => (
  <Stack sx={{ mt: 3 }} spacing={2}>
    <TextFieldControl label='Подсказка в поле' name={`elements.[${elementIndex}].placeholder`} disabled={disabled} />
    <TextFieldControl
      label='Правильный ответ'
      name={`elements.[${elementIndex}].elementParts[0].correctAnswer`}
      rules={{ required: true }}
      disabled={disabled}
      multiline
    />
  </Stack>
);

export default TextAnswer;

TextAnswer.defaultProps = {
  disabled: false
};
