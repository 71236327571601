import React, { FC } from 'react';
import { ElementFileType } from '@generated/graphql';
import { Box, Stack } from '@mui/material';
import { ButtonWithFile, Dropzone, TextFieldControl } from 'components/inputs';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import { CurrentFileFieldName, ExerciseForm, IFile } from '../../../types';
import AttachDownloadFile from './AttachDownloadFile';
import ImageFile from './ImageFile';

const SelectFiles: FC<{
  field: IFile;
  form: UseFormReturn<ExerciseForm>;
  fieldArray: UseFieldArrayReturn;
  index: number;
  elementIndex: number;
  disabled?: boolean;
}> = ({ field, form, disabled, fieldArray, index, elementIndex }) => {
  const { update } = fieldArray;
  const { watch } = form;
  const isFileExist = !!field.file;
  const type = field?.type;
  const isCover = type === ElementFileType.Cover;

  const deleteFile = (i: number) => {
    update(i, {
      type
    });
  };

  const currentName: CurrentFileFieldName = `elements.${elementIndex}.files.${index}`;

  const currentFile = watch(currentName);

  const currentFileNameWithExtension = currentFile?.file?.name;

  const fileExtension = currentFileNameWithExtension
    ? currentFileNameWithExtension.substring(
        currentFileNameWithExtension.lastIndexOf('.'),
        currentFileNameWithExtension.length
      )
    : undefined;

  if (isFileExist && isCover) return <ImageFile {...{ index, form, fieldArray, currentName, type }} />;
  if (isFileExist && !isCover) {
    return (
      <Stack spacing={1}>
        <TextFieldControl
          name={`${currentName}.fileName`}
          label='Название файла'
          rules={{ required: true }}
          endIcon={fileExtension}
        />
        <ButtonWithFile
          key={field.file?.name}
          onDelete={() => deleteFile(index)}
          file={field.file}
          disabled={disabled}
          fileName={field.fileName + fileExtension}
        />
      </Stack>
    );
  }
  if (!isFileExist && isCover) {
    return (
      <Box width='100%' height='100%' overflow='hidden'>
        <Dropzone
          setValue={(name: string, newFile: any) => {
            if (name === 'coverFile') {
              update(index, {
                ...watch(currentName),
                file: newFile
              });
            }
          }}
        />
      </Box>
    );
  }
  return <AttachDownloadFile {...{ fieldArray, elementIndex, index, form, isCover, disabled, currentName }} />;
};

export default SelectFiles;

SelectFiles.defaultProps = {
  disabled: false
};
