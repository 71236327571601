import { IconButton, Stack, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React, { useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { ToastContext, ToastContextProps, ToastTypeEnum } from 'context/toastContext';

interface InsertButtonProps {
  children: React.ReactNode;
  form: UseFormReturn;
  inputName: string;
}

const InsertButton = ({ children, form, inputName }: InsertButtonProps) => {
  const { setValue } = form;
  const { addToast } = useContext<ToastContextProps>(ToastContext);

  const readClipboardText = async () => {
    try {
      return await navigator.clipboard.readText();
    } catch (e) {
      throw new Error('Error reading clipboard text');
    }
  };

  const insertValueInInput = (value: string) => {
    setValue(inputName, value);
  };

  const handleButtonClick = async () => {
    try {
      const text = await readClipboardText();
      insertValueInInput(text);
    } catch {
      addToast({ text: 'Произошла ошибка при вставке текста', type: ToastTypeEnum.ERROR });
    }
  };

  return (
    <Stack direction='row' alignItems='center' sx={{ width: '100%' }}>
      <Tooltip title='Вставить'>
        <IconButton sx={{ color: 'primary.main', ml: -1.25 }} onClick={handleButtonClick}>
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
      {children}
    </Stack>
  );
};

export default InsertButton;
