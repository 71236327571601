import React, { FC } from 'react';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import FileAttachButton from 'components/inputs/FileAttachButton';
import { CurrentFileFieldName, ExerciseForm } from '../../../types';

const AttachDownloadFile: FC<{
  form: UseFormReturn<ExerciseForm>;
  fieldArray: UseFieldArrayReturn;
  index: number;
  disabled?: boolean;
  isCover: boolean;
  currentName: CurrentFileFieldName;
}> = ({ form, fieldArray, currentName, isCover, index, disabled }) => {
  const { watch } = form;
  const { update } = fieldArray;
  return (
    <FileAttachButton
      multiple
      text={isCover ? 'Прикрепить обложку' : 'Прикрепить файл'}
      onChange={(event: any) =>
        update(index, {
          ...watch(currentName),
          file: Array.from(event.target.files)[0]
        })
      }
      disabled={disabled}
    />
  );
};

export default AttachDownloadFile;
