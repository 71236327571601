import React, { FC } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import TextFieldControl from 'components/inputs/TextFieldControl';
import { UseFormReturn } from 'react-hook-form';
import FileAttachButton from 'components/inputs/FileAttachButton';
import ButtonWithFile from 'components/inputs/ButtonWithFile';
import ScoreSettings from './ScoreSettings';
import { ExtendedElementTypes, QuestionComponents } from '../../types';
import Feedback from '../Feedback';
import { FewAnswer, SingleAnswer, TextAnswer, Matching } from '../answers';

type QuestionProps = {
  elementIndex: number;
  disabled?: boolean;
  itemType: QuestionComponents;
  form: UseFormReturn;
};

type ComponentType = {
  [key in QuestionComponents]: FC<any>;
};

const COMPONENT: ComponentType = {
  [ExtendedElementTypes.QUESTION_FEW_ANSWERS]: FewAnswer,
  [ExtendedElementTypes.QUESTION_MATCHING]: Matching,
  [ExtendedElementTypes.QUESTION_TEXT_ANSWERS]: TextAnswer,
  [ExtendedElementTypes.QUESTION_SINGLE_ANSWER]: SingleAnswer,
  [ExtendedElementTypes.QUESTION_FILE_ANSWER]: Feedback
};

const Question: FC<QuestionProps> = ({ elementIndex, disabled, itemType, form }) => {
  const Component = itemType ? COMPONENT[itemType] : Box;
  const { setValue, watch } = form;
  const watchFile = watch(`elements.[${elementIndex}].files[0].file`);

  const deleteFile = () => {
    setValue(`elements.[${elementIndex}].files`, undefined);
  };
  return (
    <Box flexGrow={1}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Stack>
            <TextFieldControl
              minRows={4}
              multiline
              label='Описание'
              name={`elements.${elementIndex}.description`}
              rules={{
                validate: (value, formValues) => !!formValues.elements[elementIndex].title || !!value
              }}
              disabled={disabled}
            />
            <Stack direction='row' spacing={3} mt={2}>
              <FileAttachButton
                multiple
                text='Прикрепить файлы'
                onChange={(event: any) =>
                  setValue(`elements.[${elementIndex}].files[0].file`, Array.from(event.target.files)[0])
                }
                disabled={disabled}
              />
              {watchFile && (
                <ButtonWithFile
                  key={watchFile?.name}
                  onDelete={() => deleteFile()}
                  file={watchFile}
                  disabled={disabled}
                />
              )}
            </Stack>
            <Component {...{ elementIndex, itemType, form }} />
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <ScoreSettings elementIndex={elementIndex} elementType={itemType} form={form} />
        </Grid>
      </Grid>
    </Box>
  );
};
export default Question;

Question.defaultProps = {
  disabled: false
};
