import { formatISODate } from 'helpers/date';
import createUserName from 'helpers/common';
import { IconButton, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext } from 'react';
import { ModalContext } from 'context/modalContext';
import { CourseUsersQuery, useRemoveUserFromCourseMutation } from '@generated/graphql';
import { ArrayElement } from 'types/common';

type User = ArrayElement<CourseUsersQuery['courseUsers']['data']>;

const USERS_TABLE = (refetch: any, course: CourseUsersQuery['course'] | undefined) => {
  const navigate = useNavigate();
  const { handleOpen } = useContext(ModalContext);
  const { addToast } = useContext(ToastContext);
  const [removeUserFromCourse] = useRemoveUserFromCourseMutation();
  return [
    {
      title: 'ID',
      value: (user: User) => user.numericId || '—',
      loadingValue: '1355'
    },
    {
      title: 'ФИО',
      value: (user: User) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          component='button'
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/users/${user.id}`);
          }}
        >
          {createUserName(user)}
        </Link>
      ),
      loadingValue: 'Иванов Иван Иваныч'
    },
    {
      title: 'Дата получения курса',
      value: (user: User) => (user.coursePurchaseDate ? formatISODate(user.coursePurchaseDate) : '—'),
      loadingValue: '100'
    },
    {
      title: 'Количество платных блоков',
      value: (user: User) => user.coursePaidBlocksCount || '0',
      loadingValue: 'Иванов Иван Иваныч'
    },
    {
      title: 'Дата последней покупки',
      value: (user: User) => (user.courseLastPurchaseDate ? formatISODate(user.courseLastPurchaseDate) : '—'),
      loadingValue: '100'
    },
    {
      title: 'Действие',
      value: (user: User) => (
        <IconButton
          size='large'
          color='error'
          onClick={(e) => {
            e.stopPropagation();
            handleOpen({
              title: 'Удалить пользователя',
              content: `Вы уверены, что хотите удалить пользователя из курса «${course?.title}»?`,
              handleClick: async () =>
                removeUserFromCourse({
                  variables: { userId: user.id, courseId: course?.id! }
                })
                  .then(() => {
                    addToast({ type: ToastTypeEnum.SUCCESS });
                    refetch();
                  })
                  .catch(() => addToast({ type: ToastTypeEnum.ERROR }))
            });
          }}
        >
          <CloseIcon />
        </IconButton>
      ),
      tooltip: () => 'Удалить пользователя из курса',
      loadingValue: 'Х'
    }
  ];
};

export default USERS_TABLE;
