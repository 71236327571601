import { Avatar, Box, Button, IconButton, Stack, Typography } from '@mui/material';
import createUserName from 'helpers/common';
import { removeAuthCookies } from 'helpers/cookieHelper';
import ROUTES from 'constants/routes';
import * as React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'context/authContext';
import Logo from 'assets/logo.svg';
import { Logout } from '@mui/icons-material';

const Header = () => {
  const navigate = useNavigate();
  const { me, refetch, error } = useContext(AuthContext);

  return (
    <Stack
      direction='row'
      spacing={2}
      sx={(theme) => ({
        px: 4,
        py: 1,
        borderBottom: '1px solid',
        borderColor: theme.palette.base['300'],
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'sticky',
        left: 0,
        top: 0,
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.primary.contrastText,
        boxShadow: 'none'
      })}
    >
      <Box component='img' src={Logo} sx={{ width: 150 }} />
      <Stack direction='row' spacing={2} alignItems='center'>
        {me && !error ? (
          <>
            <Avatar src={me?.avatarFile?.url} sx={{ width: 24, height: 24 }} />
            <Typography>{createUserName(me)}</Typography>
            <IconButton
              onClick={() => {
                removeAuthCookies(['accessToken', 'refreshToken']);
                navigate(`${ROUTES.AUTH}`);
                refetch();
              }}
            >
              <Logout />
            </IconButton>
          </>
        ) : (
          <Button
            onClick={() => {
              navigate(`${ROUTES.AUTH}`);
            }}
          >
            Войти
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default Header;
