import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, Stack, Typography } from '@mui/material';
import TableLayout from 'layouts/TableLayout';
import React, { useEffect } from 'react';
import { ExerciseType, useUserExercisesQuery } from '@generated/graphql';
import ROUTES from 'constants/routes';
import createUserName from 'helpers/common';
import USER_EXERCISES_TABLE, { UserExercise } from './table';

const UserExercisesList = () => {
  const navigate = useNavigate();
  const { userId, courseId } = useParams<{ courseId: string; userId: string }>();
  const { data, loading, error, refetch } = useUserExercisesQuery({
    variables: {
      limit: 10,
      page: 0,
      userId: userId!,
      filter: {
        courseId: courseId!,
        types: [ExerciseType.Homework]
      }
    }
  });
  const totalCount = data?.exercises?.totalCount;
  const userExercises = data?.exercises?.data;
  const user = data?.user;

  useEffect(() => {
    document.title = 'Список домашних заданий пользователя';
  }, []);

  return (
    <>
      <Stack direction='row' justifyContent='space-between' mb={3}>
        <Typography variant='h1'>Домашние задания</Typography>
        <Card
          sx={{ cursor: 'pointer', width: 300 }}
          to={`/${ROUTES.USERS}/${user?.id}`}
          rel='noopener noreferrer'
          component={Link}
        >
          <Stack spacing={1}>
            <Typography>{`ID: ${user?.numericId}`}</Typography>
            <Typography>{`ФИО: ${createUserName(user)}`}</Typography>
          </Stack>
        </Card>
      </Stack>
      <Stack spacing={3}>
        <TableLayout
          {...{ error, totalCount, refetch }}
          loading={loading}
          data={userExercises}
          tableStructure={USER_EXERCISES_TABLE}
          defaultFilter={{
            courseId: courseId!,
            types: [ExerciseType.Homework]
          }}
          rowSx={(row: UserExercise) => ({ ...(!row.userStatistic.maxScoreB && { cursor: 'default' }) })}
          action={(row: UserExercise) =>
            row.userStatistic.maxScoreB ? navigate(`/${ROUTES.HOMEWORKS}/${row.userFileAnswer?.id}`) : {}
          }
        />
      </Stack>
    </>
  );
};

export default UserExercisesList;
