import { Card, Stack, Typography, Link } from '@mui/material';
import createUserName from 'helpers/common';
import { UserAnswerStatus, useUserAnswerQuery } from '@generated/graphql';
import { useParams } from 'react-router-dom';
import CircularLoading from 'components/CircularLoading';
import { ButtonWithFile } from 'components/inputs';
import ROUTES from 'constants/routes';
import { formatISODate } from 'helpers/date';
import { useMemo } from 'react';
import AnswerForm from './components/AnswerForm';
import HistoryMessages from './components/HistoryMessages';

export type UserAnswerElement =
  | {
      __typename?: 'QuestionElement';
      title?: string | null;
      description: string;
      maxScore?: number | null;
      id: string;
      exercise: {
        __typename?: 'Exercise';
        id: string;
        lesson: {
          __typename?: 'Lesson';
          id: string;
          title: string;
          block: { __typename?: 'Block'; id: string; title: string };
        };
      };
    }
  | undefined;

const Exercise = () => {
  const { userAnswerId } = useParams();

  const { data, loading, refetch } = useUserAnswerQuery({ variables: { userAnswerId: userAnswerId! } });
  const userAnswer = data?.userAnswer;
  const user = userAnswer?.user;
  const element = userAnswer?.element as UserAnswerElement;
  const status = userAnswer?.status;
  const studentId = userAnswer?.user.id;
  const lessonTitle = userAnswer?.element.exercise.lesson.title;
  const blockTitle = userAnswer?.element.exercise.lesson.block.title;
  const courseTitle = userAnswer?.element.exercise.lesson.block.course.title;

  const userAnswerHistory = data?.userAnswerHistory?.slice();

  const answerHistory = userAnswerHistory?.slice();
  const currentScore = answerHistory?.reverse()?.find((history) => history.score !== null)?.score;

  const messages = useMemo(
    () => userAnswerHistory?.filter((item) => item.message),
    [JSON.stringify(userAnswerHistory)]
  );

  if (loading) return <CircularLoading />;

  return (
    <Stack spacing={2}>
      <Card component={Stack} direction='row'>
        <Stack spacing={1} flex={2}>
          <Typography variant='h6'>{`Курс: ${courseTitle}`}</Typography>
          <Typography variant='h6'>{`Блок: ${blockTitle}`}</Typography>
          <Typography variant='h6'>{`Урок: ${lessonTitle}`}</Typography>
        </Stack>
        <Stack spacing={1} flex={1}>
          <Typography>{`ID: ${user?.numericId}`}</Typography>
          <Link href={`/${ROUTES.USERS}/${user?.id}`} rel='noopener noreferrer' variant='body1'>
            {`ФИО: ${createUserName(user)}`}
          </Link>
        </Stack>
      </Card>
      <Card>
        <Stack spacing={1}>
          <Typography variant='h6'>{element?.title}</Typography>
          <Typography sx={{ whiteSpace: 'pre-wrap' }}>{element?.description}</Typography>
        </Stack>
      </Card>
      <Card component={Stack} spacing={2}>
        <Stack spacing={1} direction='row' alignItems='center'>
          <Typography variant='h5' mb={2}>
            Ответ пользователя
          </Typography>
          {!!userAnswer?.createdAt && (
            <Typography variant='text6'>{formatISODate(userAnswer?.createdAt, 'dd.MM.yyyy HH:mm')}</Typography>
          )}
        </Stack>
        <ButtonWithFile file={userAnswer?.file} />
      </Card>
      <HistoryMessages messages={messages} studentId={studentId!} />
      {(status === UserAnswerStatus.InProgress || status === UserAnswerStatus.ReplyReceived) && (
        <AnswerForm maxScore={element?.maxScore!} currentScore={currentScore} refetchMessages={refetch} />
      )}
    </Stack>
  );
};

export default Exercise;
