import { formatISODate } from 'helpers/date';
import { HOMEWORK_STATUS_COLOR, HOMEWORK_STATUS_NAME, SUBJECT_NAME } from 'constants/global';
import { AdminUserAnswerListOrderFields, CourseSubject, UserAnswerStatus } from '@generated/graphql';
import { Chip, IconButton } from '@mui/material';
import createUserName from 'helpers/common';
import React from 'react';
import { Link } from 'react-router-dom';
import { Checklist } from '@mui/icons-material';

type Homework = {
  __typename?: 'UserAnswer';
  id: string;
  updatedAt: any;
  status?: UserAnswerStatus | null;
  element:
    | {
        __typename?: 'FeedbackElement';
        id: string;
        exercise: {
          __typename?: 'Exercise';
          id: string;
          lesson: {
            __typename?: 'Lesson';
            id: string;
            block: {
              __typename?: 'Block';
              id: string;
              course: { __typename?: 'Course'; id: string; subject: CourseSubject; grade: number };
            };
          };
        };
      }
    | {
        __typename?: 'FileElement';
        id: string;
        exercise: {
          __typename?: 'Exercise';
          id: string;
          lesson: {
            __typename?: 'Lesson';
            id: string;
            block: {
              __typename?: 'Block';
              id: string;
              course: { __typename?: 'Course'; id: string; subject: CourseSubject; grade: number };
            };
          };
        };
      }
    | {
        __typename?: 'HeadingElement';
        id: string;
        exercise: {
          __typename?: 'Exercise';
          id: string;
          lesson: {
            __typename?: 'Lesson';
            id: string;
            block: {
              __typename?: 'Block';
              id: string;
              course: { __typename?: 'Course'; id: string; subject: CourseSubject; grade: number };
            };
          };
        };
      }
    | {
        __typename?: 'QuestionElement';
        id: string;
        exercise: {
          __typename?: 'Exercise';
          id: string;
          lesson: {
            __typename?: 'Lesson';
            id: string;
            block: {
              __typename?: 'Block';
              id: string;
              course: { __typename?: 'Course'; id: string; subject: CourseSubject; grade: number };
            };
          };
        };
      }
    | {
        __typename?: 'TextElement';
        id: string;
        exercise: {
          __typename?: 'Exercise';
          id: string;
          lesson: {
            __typename?: 'Lesson';
            id: string;
            block: {
              __typename?: 'Block';
              id: string;
              course: { __typename?: 'Course'; id: string; subject: CourseSubject; grade: number };
            };
          };
        };
      }
    | {
        __typename?: 'VideoElement';
        id: string;
        exercise: {
          __typename?: 'Exercise';
          id: string;
          lesson: {
            __typename?: 'Lesson';
            id: string;
            block: {
              __typename?: 'Block';
              id: string;
              course: { __typename?: 'Course'; id: string; subject: CourseSubject; grade: number };
            };
          };
        };
      };
  user: {
    __typename?: 'User';
    id: string;
    numericId: number;
    firstName?: string | null;
    lastName?: string | null;
    middleName?: string | null;
  };
};

const HOMEWORK_TABLE = [
  {
    title: 'ID',
    value: (homework: Homework) => homework.user?.numericId || '—',
    loadingValue: '1355'
  },
  {
    title: 'ФИО',
    value: ({
      user
    }: {
      user: {
        firstName: string;
        lastName: string;
        middleName: string;
      };
    }) => createUserName(user),
    loadingValue: 'Иванов Иван Иваныч'
  },
  {
    title: 'Предмет',
    value: ({
      element: {
        exercise: {
          lesson: {
            block: {
              course: { subject }
            }
          }
        }
      }
    }: {
      element: Homework['element'];
    }) => (subject ? SUBJECT_NAME[subject] : '—'),
    loadingValue: '100'
  },
  {
    title: 'Класс',
    value: ({
      element: {
        exercise: {
          lesson: {
            block: {
              course: { grade }
            }
          }
        }
      }
    }: {
      element: Homework['element'];
    }) => grade || '—',
    loadingValue: '100'
  },
  {
    title: 'Дата изменения',
    value: (homework: any) => (homework.updatedAt ? formatISODate(homework.updatedAt, 'dd.MM.yyyy HH:mm') : '—'),
    loadingValue: '100',
    sortName: AdminUserAnswerListOrderFields.UpdatedAt
  },
  {
    title: 'Статус',
    value: ({ status }: { status: UserAnswerStatus }) => (
      <Chip
        label={HOMEWORK_STATUS_NAME[status]}
        sx={{ backgroundColor: HOMEWORK_STATUS_COLOR[status] }}
        variant='outlined'
      />
    ),
    loadingValue: 'ХXXXXXXXXX'
  },
  {
    title: '',
    value: (homework: Homework) => (
      <IconButton
        size='large'
        component={Link}
        onClick={(e) => e.stopPropagation()}
        to={`${homework.element.exercise.lesson.block.course.id}/${homework.user.id}`}
        rel='noopener noreferrer'
      >
        <Checklist />
      </IconButton>
    ),
    tooltip: () => 'Список всех ДЗ пользователя',
    loadingValue: 'Х'
  }
];

export default HOMEWORK_TABLE;
