import React, { FC } from 'react';
import { Button, Stack } from '@mui/material';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import Answer from 'pages/Course/view/tabs/Content/components/ExerciseConstructor/elements/answers/SingleAnswer/Answer';

type SingleAnswerProps = {
  form: UseFormReturn;
  elementIndex: number;
};

const SingleAnswer: FC<SingleAnswerProps> = ({ form, elementIndex }) => {
  const { control } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    keyName: 'formId',
    name: `elements.[${elementIndex}].elementParts`
  });

  const appendRadio = () => {
    append({ isCorrect: false, answer: '' });
  };

  const removeRadio = (index: number) => {
    remove(index);
  };

  return (
    <Stack sx={{ mt: 3 }}>
      <Answer parentName={`elements.[${elementIndex}]`} itemList={fields} removeRadio={removeRadio} form={form} />
      <Button sx={{ mt: 3, width: 'fit-content' }} startIcon={<AddIcon />} onClick={appendRadio}>
        Добавить вариант ответа
      </Button>
    </Stack>
  );
};

export default SingleAnswer;
