const createUserName = (
  user:
    | {
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        middleName?: string | null | undefined;
      }
    | undefined
    | null
) => {
  if (!user) return '—';
  const { firstName, lastName, middleName } = user;
  return firstName || lastName || middleName ? [firstName, lastName, middleName].join(' ') : '—';
};

export default createUserName;
