import React, { FC } from 'react';
import { Button, SxProps, Typography } from '@mui/material';
import { Close, InsertDriveFileOutlined } from '@mui/icons-material';
import { Scalars } from '@generated/graphql';

type ButtonWithFileProps = {
  onDelete?: () => any;
  file?: {
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    url: Scalars['String']['output'];
  } | null;
  disabled?: boolean;
  sx?: SxProps;
  fileName?: string;
};

const ButtonWithFile: FC<ButtonWithFileProps> = ({ onDelete, file, disabled, sx, fileName }) => (
  <Button
    sx={{ width: 'fit-content', ...sx }}
    variant='outlined'
    size='small'
    endIcon={onDelete && !disabled ? <Close onClick={onDelete} /> : undefined}
    startIcon={<InsertDriveFileOutlined />}
  >
    <Typography
      sx={{ width: 160, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
      onClick={() => {
        if (file?.url) {
          window.location.href = file.url;
        }
      }}
    >
      {fileName || file?.name || 'file'}
    </Typography>
  </Button>
);

export default ButtonWithFile;

ButtonWithFile.defaultProps = {
  file: undefined,
  disabled: false,
  sx: {},
  onDelete: undefined
};
