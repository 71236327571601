import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { AdminUserListOrderFields, OrderType, RoleName, UsersQuery, useUsersQuery } from '@generated/graphql';
import { ArrayElement } from 'types/common';
import createUserName from 'helpers/common';
import { ROLE_NAME } from 'constants/global';
import SkeletonTable from 'components/SkeletonTable';
import React, { FC, useContext, useState } from 'react';
import { ENUM_PAGE } from 'layouts/TableLayout';
import { CourseCurator } from 'pages/Course/view/tabs/Curators/index';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';

export type User = ArrayElement<UsersQuery['users']['data']>;

const TABLE_STRUCTURE = [
  {
    title: 'ID',
    value: (user: User) => user.numericId,
    loadingValue: '1234'
  },
  {
    title: 'Имя',
    value: (user: User) => createUserName(user),
    loadingValue: 'Иванов Иван'
  },
  {
    title: 'Роль',
    value: (user: User) => (user.role ? ROLE_NAME[user.role] : undefined),
    loadingValue: 'куратор'
  }
];

const UserListModal: FC<{
  openModal: boolean;
  setOpenModal: (open: boolean) => any;
  append: any;
  fields: CourseCurator[];
}> = ({ openModal, setOpenModal, append, fields }) => {
  const { addToast } = useContext(ToastContext);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);

  const { data, loading, error, refetch } = useUsersQuery({
    variables: {
      page: 0,
      limit: 10,
      filter: { role: RoleName.Curator },
      order: { field: AdminUserListOrderFields.NumericId, order: OrderType.Asc }
    }
  });

  const onChangeLimit = (newLimit: number) => {
    setLimit(newLimit);
    setPage(0);
    refetch({ page: 0, limit: newLimit });
  };

  const onChangePage = (newPage: number) => {
    setPage(newPage);
    refetch({ page: newPage, limit });
  };
  const totalCount = data?.users?.totalCount;
  const users = data?.users?.data;

  const [selected, setSelected] = useState<CourseCurator>();

  return (
    <Dialog open={openModal} onClose={() => setOpenModal(false)}>
      <DialogTitle>
        <Typography variant='h3' textAlign='center'>
          Добавить куратора
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack py={1}>
          <TextField
            label='Поиск'
            placeholder='ID, ФИО'
            onChange={(event) => {
              const { value } = event.target;
              refetch({
                filter: {
                  ...(Number.isNaN(+value) ? { fullName: value } : { numericId: parseInt(value, 10) })
                }
              });
            }}
          />
          <Table>
            <TableHead>
              <TableRow>
                {TABLE_STRUCTURE.map((cell) => (
                  <TableCell>{cell.title}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading || error ? (
              <SkeletonTable rows={10} columns={TABLE_STRUCTURE} />
            ) : (
              <TableBody>
                {users && users?.length !== 0 ? (
                  users.map((user) => (
                    <TableRow
                      hover
                      sx={{
                        cursor: 'pointer',
                        background: (theme) => (user.id === selected?.user?.id ? theme.palette.divider : 'inherit'),
                        '&:hover': {
                          background: (theme) =>
                            user.id === selected?.user?.id ? `${theme.palette.divider}!important` : 'inherit'
                        }
                      }}
                      onClick={() => setSelected({ user } as unknown as CourseCurator)}
                    >
                      {TABLE_STRUCTURE.map((cell) => (
                        <TableCell>{cell.value(user) || '-'}</TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell sx={{ textAlign: 'center' }} colSpan={TABLE_STRUCTURE?.length || 0}>
                      Нет данных
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component='div'
            count={totalCount || 0}
            rowsPerPage={Number(limit) || 10}
            page={Number(page) || 0}
            onPageChange={(_, newPage) => onChangePage(newPage)}
            onRowsPerPageChange={(event) =>
              onChangeLimit(parseInt((event.target as HTMLTextAreaElement | HTMLInputElement).value, 10))
            }
            showFirstButton
            showLastButton
            labelRowsPerPage='Количество строк'
            labelDisplayedRows={({ from, to, count }) =>
              `${from}–${to} из ${count !== -1 ? count : `больше, чем ${to}`}`
            }
            getItemAriaLabel={(type) => `Перейти на ${ENUM_PAGE[type]} страницу`}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenModal(false)}>Отменить</Button>
        <Button
          onClick={() => {
            if (fields?.find((user) => user.user.id === selected?.user.id)) {
              addToast({ type: ToastTypeEnum.ERROR, text: 'Пользователь уже добавлен' });
            } else {
              append(selected);
              setOpenModal(false);
            }
          }}
        >
          Добавить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserListModal;
