import React, { ChangeEventHandler, FC } from 'react';
import { Button, SxProps } from '@mui/material';
import { AttachFileOutlined } from '@mui/icons-material';

export type FileAttachButtonProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  multiple?: boolean;
  text: string;
  accept?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  sx?: SxProps;
};

const FileAttachButton: FC<FileAttachButtonProps> = ({ onChange, multiple, text, accept, disabled, fullWidth, sx }) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label>
    <input
      accept={accept}
      multiple={multiple}
      type='file'
      style={{ display: 'none' }}
      onChange={onChange}
      disabled={disabled}
    />
    <Button
      fullWidth={fullWidth}
      startIcon={<AttachFileOutlined />}
      component='span'
      size='small'
      sx={{ cursor: 'initial', ...sx }}
      disabled={disabled}
    >
      {text}
    </Button>
  </label>
);

export default FileAttachButton;

FileAttachButton.defaultProps = {
  disabled: false,
  fullWidth: false,
  sx: {},
  multiple: false,
  accept: undefined
};
